<template>
  <el-dialog
    :visible.sync="showDialog"
    title="查看设备"
    :modal-append-to-body="false"
    append-to-body
    :modal="true"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
    width="970px"
  >
    <div>
      <!--
      要resetFields起作用，必须配置:model和prop
    -->
      <el-divider></el-divider>
      <el-row class="button-group">
        <el-button
          type="primary"
          size="small"
          plain
          icon="el-icon-circle-plus"
          @click="handleSync"
          >同步车道</el-button
        >
      </el-row>
      <el-table
        :data="tableData"
        style="min-height: 400px"
        v-loading="loading"
        stripe
        @sort-change="sortChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          prop="channelName"
          label="车道名称"
          width="200"
        ></el-table-column>
        <el-table-column
          prop="parkId"
          label="停车云创建的车场id"
          width="200"
        ></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-popover
              placement="left"
              width="300"
              trigger="click"
              @show="openQRCode(row)"
              @hide="closeQRCode(row)"
            >
              <div
                :ref="'qrcode_' + row.id"
                style="width: 300px; height: 300px"
              ></div>
              <el-link type="primary" :underline="false" slot="reference"
                >车道二维码</el-link
              >
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="pageIndex"
        :total="totalElements"
        :page-sizes="pageSizeList"
        @current-change="changePage"
        @size-change="pageSizeChange"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </div>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import parkingChannelApi from "@/api/base/parkingChannel";
import QRCode from "qrcodejs2";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  props: ["parkId", "companyId"],
  data() {
    var self = this;

    return {
      queryModel: {
        id: "",
        channelId: "",
        channelName: "",
        parkId: "",
        delFlag: "",
        createBy: "",
        createTime: "",
        updateBy: "",
        updateTime: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      showDialog: true,
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("id", self.queryModel.id);
      formData.append("parkId", self.parkId);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      parkingChannelApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order;

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleSync(record) {
      var self = this;

      var formData = new FormData();
      formData.append("parkId", self.parkId);

      self.loading = true;

      return parkingChannelApi.sync(formData).then(function (response) {
        self.loading = false;

        var jsonData = response.data;
        self.changePage(0);
        if (jsonData.result) {
          self.$message({
            message: "同步成功!",
            type: "success",
          });
        } else {
          self.$message({
            message: jsonData.message + "",
            type: "warning",
          });
        }
      });
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          parkingChannelApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        parkingChannelApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    openQRCode(record) {
      //el.innerHTML = "";
      var self = this;
      self.$refs["qrcode_" + record.id].innerHTML = "";
      //车道扫码
      var qrtext =
        process.env.VUE_APP_PARKING_CHANNEL_URL +
        "?companyId=" +
        self.companyId +
        "&channelId=" +
        record.id;

      var el = self.$refs["qrcode_" + record.id];

      var qr = new QRCode(el, {
        text: qrtext,
        width: 300,
        height: 300,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    closeQRCode(record) {
      self.$refs["qrcode_" + record.id].innerHTML = "";
    },
  },
  mounted: function () {
    this.changePage(1);
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>