
<style scoped>
.user-panel {
  margin: 10px auto;
}
</style>
<template>
  <el-dialog
    :visible.sync="showDialog"
    :title="title"
    :modal-append-to-body="false"
    style="text-align: left"
    @close="closeDialog"
    :close-on-click-modal="false"
  >
    <div class="user-panel" v-loading="loading">
      <el-form
        ref="form"
        :model="formModel"
        :rules="ruleValidate"
        :label-width="'100px'"
      >
        <el-form-item label="名称" prop="parkingName">
          <el-input
            v-model="formModel.parkingName"
            placeholder="请输入名称"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="停车场编号" prop="parkId">
          <el-input
            v-model="formModel.parkId"
            placeholder="请输入停车场编号"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="上级" prop="parentId">
          <el-select-tree
            size="mini"
            :props="parentProps"
            :options="parentResult"
            v-model="formModel.parentId"
            height="200"
          ></el-select-tree>
        </el-form-item>
        <el-form-item label="所属公司" prop="companyId">
          <el-select-tree
            :props="props"
            :options="companyResult"
            v-model="formModel.companyId"
            height="200"
            style="width: 230px"
          ></el-select-tree>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">取 消</el-button>
      <el-button type="primary" @click="handleSubmit" :loading="submitting"
        >确 定</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import parkingInfoApi from "@/api/base/parkingInfo";
import SelectTree from "@/components/SelectTree";
import companyInfoApi from "@/api/base/companyInfo";

export default {
  props: ["businessKey", "title"],
  data() {
    return {
      formModel: {},
      ruleValidate: {
        parkingName: [
          { required: true, message: "名称不能为空", trigger: "blur" },
        ],
        parkId: [
          { required: true, message: "停车场编号不能为空", trigger: "blur" },
        ],
      },
      showDialog: true,
      loading: false,
      submitting: false,
      parentResult: [],
      parentProps: {
        value: "id",
        label: "parkingName",
      },
      companyResult: [],
      
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
    };
  },
  created() {
    var self = this;

    parkingInfoApi.query().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        self.parentResult = jsonData.data;
      }
    });

    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;
      if (jsonData.result) {
        if (jsonData.data != null && jsonData.data != "") {
          self.companyResult = jsonData.data;
        }
      }
    });
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    handleSubmit() {
      var self = this;

      this.$refs["form"].validate((valid) => {
        if (valid) {
          (function () {
            var id = self.formModel.id;

            if (id == null || id.length == 0) {
              return parkingInfoApi.add(self.formModel);
            } else {
              return parkingInfoApi.update(self.formModel);
            }
          })().then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              self.$message({
                message: "保存成功!",
                type: "success",
              });

              self.$emit("close", true);
            } else {
              self.$message({
                message: jsonData.message + "",
                type: "warning",
              });

              self.$emit("close", false);
            }
          });
        }
      });
    },
  },
  mounted: function () {
    var self = this;

    (function () {
      if (self.businessKey.length == 0) {
        return parkingInfoApi.create();
      } else {
        return parkingInfoApi.edit(self.businessKey);
      }
    })()
      .then((response) => {
        var jsonData = response.data;
        self.loading = false;

        if (jsonData.result) {
          self.formModel = jsonData.data;
        } else {
          self.$message.error(jsonData.message + "");
        }
      })
      .catch((error) => {
        self.$message.error(error + "");
      });
  },
  components: {
    "el-select-tree": SelectTree,
  },
};
</script>