import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData) {
  return request.post(constant.serverUrl + "/base/parkingInfo/pageList", formData);
}

function create() {
  return request.get(constant.serverUrl + "/base/parkingInfo/create");
}

function edit(id) {
  return request.get(constant.serverUrl + "/base/parkingInfo/edit/" + id);
}

function add(formModel) {
  return request.post(constant.serverUrl + "/base/parkingInfo/add", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel) {
  return request.post(constant.serverUrl + "/base/parkingInfo/update", formModel, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id) {
  return request.post(constant.serverUrl + "/base/parkingInfo/delete/" + id);
}

function batchRemove(idList) {
  return request.post(constant.serverUrl + "/base/parkingInfo/batchDelete", idList, {
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function query() {
  return request.post(constant.serverUrl + "/base/parkingInfo/query");
}

export default {
  pageList, create, edit, add, update, remove, batchRemove, query
}