<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">物业管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">车位收费管理</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/base/parkingInfo">车位管理</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="名称" prop="parkingName">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.parkingName"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="上级" prop="parentId">
        <el-select-tree
          size="mini"
          :props="parentProps"
          :options="parentResult"
          v-model="queryModel.parentId"
          height="200"
        ></el-select-tree>
      </el-form-item> -->
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleAdd"
        >新增</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchDelete"
        >删除选中项</el-button
      >
    </el-row>
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="parkingName"
        label="名称"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="parkId"
        label="停车场编号"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="parentName"
        label="上级"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="companyName"
        label="所属物业公司"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="updateTime"
        label="更新时间"
        width="180"
      ></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="{ row }">
          <el-row>
            <el-col>
              <el-link
                type="primary"
                @click="handleOpenChannel(row)"
                v-if="row.parkId != 0"
                >查看车道</el-link
              >-
              <el-popover
                placement="left"
                width="300"
                trigger="click"
                @show="openQRCode(row)"
                @hide="closeQRCode(row)"
              >
                <div
                  :ref="'qrcode_' + row.id"
                  style="width: 300px; height: 300px"
                ></div>
                <el-link type="primary" :underline="false" slot="reference"
                  >缴费二维码</el-link
                >
              </el-popover>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-link type="warning" @click="handleEdit(row)">编辑</el-link>-
              <el-link type="danger" @click="handleDelete(row)">删除</el-link>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <parkingInfo-detail
      v-if="showModal"
      :businessKey="businessKey"
      :title="modalTitle"
      @close="onDetailModalClose"
    ></parkingInfo-detail>
    <parkingChannel-list
      v-if="showChannelModal"
      :parkId="parkId"
      :companyId="companyId"
      @close="onDetailModalClose"
    ></parkingChannel-list>
  </div>
</template>
<script>
import Constant from "@/constant";
import ParkingInfoDetail from "./parkingInfo-detail";
import ParkingChannelList from "./parkingChannel-list";
import parkingInfoApi from "@/api/base/parkingInfo";
import QRCode from "qrcodejs2";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import SelectTree from "@/components/SelectTree";

export default {
  data() {
    var self = this;

    return {
      queryModel: {
        id: "",
        companyId: "",
        parkingName: "",
        parentId: "",
        ownerId: "",
        parkingPrice: "",
        rentPrice: "",
        contractType: "",
        effectiveTime: "",
        expirationTime: "",
        delFlag: "",
        createBy: "",
        createTime: "",
        updateBy: "",
        updateTime: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      parentResult: [],
      parentProps: {
        value: "id",
        label: "parkingName",
      },
      showChannelModal: false,
      parkId: "",
    };
  },
  // created() {
  //   var self = this;

  //   parkingInfoApi.query().then(function (response) {
  //     var jsonData = response.data;
  //     if (jsonData.result) {
  //       self.parentResult = jsonData.data;
  //     }
  //   });
  // },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("parkingName", self.queryModel.parkingName);
      formData.append("parentId", self.queryModel.parentId);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      parkingInfoApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    handleOpenChannel(record) {
      this.parkId = record.parkId;
      this.companyId = record.companyId;
      this.showChannelModal = true;
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          parkingInfoApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        parkingInfoApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;
      this.showChannelModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    openQRCode(record) {
      //el.innerHTML = "";
      var self = this;
      self.$refs["qrcode_" + record.id].innerHTML = "";
      //非设备扫码
      var qrtext =
        process.env.VUE_APP_PARKING_PAY_ULR + "?companyId=" + record.companyId;

      var el = self.$refs["qrcode_" + record.id];

      var qr = new QRCode(el, {
        text: qrtext,
        width: 300,
        height: 300,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    closeQRCode(record) {
      self.$refs["qrcode_" + record.id].innerHTML = "";
    },
  },
  mounted: function () {
    this.changePage(1);
  },
  components: {
    "parkingInfo-detail": ParkingInfoDetail,
    "parkingChannel-list": ParkingChannelList,
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>